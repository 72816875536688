var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getIn, setIn, shallowEqual, isPlainObject, } from "@dhmk/utils";
export { mergeDeep } from "@dhmk/utils";
export function createLens(set, get, path) {
    var normPath = typeof path === "string" ? [path] : path;
    var _set = function (partial, replace) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        return set.apply(void 0, __spreadArray([function (parentValue) {
                var ourOldValue = getIn(parentValue, normPath);
                var ourTmpValue = typeof partial === "function" ? partial(ourOldValue) : partial;
                var isPlain = isPlainObject(ourOldValue);
                var ourNextValue = replace || !isPlain
                    ? ourTmpValue
                    : __assign(__assign({}, ourOldValue), ourTmpValue);
                var isSame = isPlain
                    ? shallowEqual(ourOldValue, ourNextValue)
                    : ourOldValue === ourNextValue; // todo Object.is
                return isSame
                    ? parentValue
                    : setIn(parentValue, normPath, ourNextValue);
            },
            false], args, false));
    };
    var _get = function () { return getIn(get(), normPath); };
    return [_set, _get];
}
var LENS_TAG = "@dhmk/LENS_TAG";
var isLens = function (x) { return !!x && x[LENS_TAG]; };
export function lens(fn) {
    var self = function (set, get, api, path) {
        var _a = createLens(set, get, path), _set = _a[0], _get = _a[1];
        return fn(_set, _get, api, path);
    };
    self[LENS_TAG] = true;
    return self;
}
var findLensAndCreate = function (x, set, get, api, path) {
    var _a, _b;
    if (path === void 0) { path = []; }
    var res = x;
    if (isPlainObject(x)) {
        res = {};
        var keys = Array().concat(Object.getOwnPropertyNames(x), (_b = (_a = Object.getOwnPropertySymbols) === null || _a === void 0 ? void 0 : _a.call(Object, x)) !== null && _b !== void 0 ? _b : [] // ie 11
        );
        keys.forEach(function (k) {
            var v = x[k];
            // Symbol props are only for storing metadata
            if (typeof k === "symbol") {
                res[k] = v;
                return;
            }
            if (isLens(v)) {
                v = v(set, get, api, path.concat(k));
            }
            res[k] = findLensAndCreate(v, set, get, path.concat(k));
        });
    }
    return res;
};
var withLensesImpl = function (config) { return function (set, get, api) {
    // @ts-ignore
    var obj = typeof config === "function" ? config(set, get, api) : config;
    return findLensAndCreate(obj, set, get, api);
}; };
export var withLenses = withLensesImpl;
export var customSetter = function (setter) { return function (fn) { return function (set, get, api, path) {
    return fn(setter(set), get, api, path);
}; }; };
export var namedSetter = customSetter(function (set) { return function (partial, name, replace) { return set(partial, replace, name); }; });
