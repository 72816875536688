var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getIn = function (x, path) { return path.reduce(function (src, k) { return src[k]; }, x); };
export var setIn = function (x, path, v) {
    if (path.length === 0) {
        return typeof v === "function" ? v(x) : v;
    }
    var k = path[0], rest = path.slice(1);
    var value = setIn(x[k], rest, v);
    return Array.isArray(x)
        ? arraySet(x, Number(k), value)
        : objectSet(x, k, value);
};
var arraySet = function (x, k, v) { return x.map(function (_v, i) { return (i === k ? v : _v); }); };
var objectSet = function (x, k, v) {
    var _a;
    return (__assign(__assign({}, x), (_a = {}, _a[k] = v, _a)));
};
