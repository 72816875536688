var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var noop = function () { };
export var id = function (x) { return x; };
export var range = function (count, start) {
    if (start === void 0) { start = 0; }
    return Array.from(Array(count).keys()).map(function (i) { return start + i; });
};
export var arraysEqual = function (a, b) {
    return a.length === b.length && a.every(function (av, i) { return av === b[i]; });
};
export var shallowEqual = function (a, b) {
    var ka = Object.keys(a);
    var kb = Object.keys(b);
    return ka.length === kb.length && ka.every(function (k) { return a[k] === b[k]; });
};
export function objectFrom(entries) {
    return Array.from(entries).reduce(function (res, _a) {
        var k = _a[0], v = _a[1];
        res[k] = v;
        return res;
    }, {});
}
export function objectMap(src, mapValue, mapKey) {
    if (mapKey === void 0) { mapKey = id; }
    return objectFrom(Object.keys(src).map(function (k) { return [
        mapKey(k, src[k], src),
        mapValue(src[k], k, src),
    ]; }));
}
export function objectTransform(src, mapEntry) {
    return objectFrom(Object.keys(src)
        .map(function (k) { return mapEntry(src[k], k, src); })
        .filter(Boolean));
}
export function getter(t, k, value) {
    if (arguments.length === 2) {
        return { use: function (value) { return getter(t, k, value); } };
    }
    else {
        Object.defineProperty(t, k, { value: value, enumerable: true });
        return value;
    }
}
export function createFactory(c) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        return new (c.bind.apply(c, __spreadArray([void 0], args, false)))();
    };
}
export function createFactoryAs() {
    return function (c) {
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            // @ts-ignore
            return new (c.bind.apply(c, __spreadArray([void 0], args, false)))();
        };
    };
}
export var call = function (fn) { return fn(); };
export var deepReadonly = function (x) { return x; };
export var merge = function (a, b) {
    return b ? __assign(__assign({}, a), b) : function (b) { return (__assign(__assign({}, b), a)); };
};
var mergeDeepRec = function (a, b) {
    if (!isPlainObject(a) || !isPlainObject(b))
        return b;
    var res = __assign({}, a);
    for (var k in b) {
        var v = b[k];
        res[k] = mergeDeepRec(a[k], v);
    }
    return res;
};
export var mergeDeep = function (a, b) {
    return b ? mergeDeepRec(a, b) : function (b) { return mergeDeepRec(b, a); };
};
export var join = function (a, sep, b) { return (a + sep + b); };
export var namespace = function (s) {
    return function (suffix) {
        return join(s, "/", suffix);
    };
};
export function assert(condition, msg) {
    if (msg === void 0) { msg = "Assertion failed"; }
    if (!condition)
        throw new Error(msg);
}
export var isPlainObject = function (x) {
    return !!x && typeof x === "object" && Object.getPrototypeOf(x) === Object.prototype;
};
export function flatMap(src, fn) {
    var res = [];
    src.forEach(function (x, i) { return res.push.apply(res, fn(x, i)); });
    return res;
}
